<template v-if="formActive">

  <div class="row mb-2">
    <div class="col-3 fw-bold">{{$t(reversePortOrder ? 'manifest.ports_inv' : 'manifest.ports')}}&nbsp;
      <button type="button" class="btn p-0" @click="setPortOrder"><i class="fa fa-rotate"></i></button>
    </div>
    <div class="col-1 fw-bold text-center">Approved</div>
    <div class="col-1p5 fw-bold text-center">Pending Loading</div>
    <div class="col-0p5 fw-bold">Type</div>
    <div class="col-1p5 fw-bold">Approval</div>
    <div class="col-0p5 fw-bold">Printed</div>
    <div class="col-1 fw-bold">{{$t('overview.status')}}</div>
    <div class="col-3">
      <FormItem type="select" :options="operatedByOptions" v-model="operatedByOfficeId" :selectDefault="false" @change="filterByOperator" />
    </div>
  </div>
  <p v-show="chosenPorts.length == 0">{{ $t('form.no_found', [$tc('navigation.bills_of_lading', 2)]) }}</p>
  <div :class="{ 'd-none': !listLoading }"> <i class="fas fa-spinner fa-spin"></i> {{$t('form.please_wait')}} </div>
  <template v-if="chosenPorts.length > 0 && !listLoading">
      <div class="header-part-1">
        <template v-for="pol in (reversePortOrder ? chosenPortsReverse : chosenPorts)" :key="pol.id">
          <div class="row data-tr" :class="{'published': pol.status == 'published'}">
            <div class="col-3 fw-bold pt-1">
              {{pol.name}}
            </div>
            <div class="col-1 text-center"> 
              <i v-show="pol.approved_count == pol.bl_count" class="fa fa-check text-success"></i> 
              <span v-show="pol.approved_count != pol.bl_count">{{pol.approved_count + ' / ' + pol.bl_count}}</span> 
            </div>
                <div class="col-1p5 text-center">
                  <i v-show="pol.loaded_count == pol.unit_count" class="fa fa-check text-success"></i> 
                  <span v-show="pol.loaded_count != pol.unit_count">{{(pol.unit_count - pol.loaded_count) + ' / ' + pol.unit_count}}</span> 
                </div>
            <div class="col-0p5"> </div>
            <div class="col-1p5"> <span v-show="pol.request_count">{{$t('form.requested')}}: {{pol.request_count}}</span> </div>
            <div class="col-0p5"> </div>
          </div>
          <template v-for="pod in pol.pods" :key="pod.id">
            <div class="row data-tr" :class="{'published': pod.status == 'published'}">
              <div class="col-3 ps-2">
                <button :id="'btn-'+pol.id+'-'+pod.id" class="btn py-0 px-2 no-box-shadow" type="button" @click="pod.expanded = !pod.expanded" data-bs-toggle="collapse" :data-bs-target="'#collapse-'+pol.id+'-'+pod.id">
                  <i class="fa fa-chevron-right" :class="{'rotate-90deg': pod.expanded}"></i>
                </button>
                <label class="col-form-label col-form-label-sm auto-check-label ps-1 fw-bold" :for="'btn-'+pol.id+'-'+pod.id">{{pod.name}}</label>
              </div>
              <div class="col-1 pt-1 text-center"> 
                <i v-show="pod.approved_count == pod.bl_count" class="fa fa-check text-success"></i> 
                <span v-show="pod.approved_count != pod.bl_count">{{pod.approved_count + ' / ' + pod.bl_count}}</span> 
              </div>
                <div class="col-1p5 pt-1 text-center">
                  <i v-show="pod.loaded_count == pod.unit_count" class="fa fa-check text-success"></i> 
                  <span v-show="pod.loaded_count != pod.unit_count">{{(pod.unit_count - pod.loaded_count) + ' / ' + pod.unit_count}}</span> 
                </div>
              <div class="col-0p5"> </div>
              <div class="col-1p5"> <span v-show="pod.request_count">{{$t('form.requested')}}: {{pod.request_count}}</span> </div>
              <div class="col-0p5"> </div>
              <div class="col-4 col-form-label-sm" v-if="pod.status == 'published'">
                <span>Published: {{pod.publish_info}}</span>&nbsp;
                <span class="float-right btn btn-sm p-0 btn-link" @click="unpublish(pol, pod)">{{$t('form.undo')}}</span>
              </div>
              <div class="col-4" v-else>
                <FormItem :id="'qstatus-'+pol.id+'-'+pod.id" type="radiogroup-status" v-model="pod.status" sizeItem="12" @onclick="(e) => publish(e, pol, pod)" :disabled="!canManifest"
                          :options="[{id: 'open', name: 'Open'}, {id: 'closed', name: 'Closed'}, {id: 'published', name: 'Published', disabled: (pod.approved_count < pod.bl_count || pod.loaded_count < pod.bl_count)}]" />
              </div>
            </div>
            <div :id="'collapse-' +pol.id + '-' + pod.id" class="collapse">
              <div class="row data-tr" v-for="bl in pod.bills_of_lading" :key="bl.id" :class="{'bg-approved': bl.supervisor_approval_id == 4, 'bg-declined': bl.supervisor_approval_id == 3, 'bg-chosen': selectedBL.id == bl.id}" @click="setChosenBLs($event, bl, pod, pol)">
                <div class="col-1 pb-1 text-end" style="width: 27px">
                  <input :id="'bl-'+bl.id+'-'+pol.id+'-'+pod.id" class="form-check-input" v-model="bl.supervisor_approved" type="checkbox" @change="setAccepted(bl, pol, pod)" :disabled="!canEdit" />
                </div>
                <div class="col-1" style="width: 31.5%; padding-left: 7px; padding-top: 1px">
                  <a class="col-form-label col-form-label-sm auto-check-label pt-1 ps-1" :href="'/billsoflading#'+bl.id" target="_blank">{{bl.name}}</a>
                </div>
                <div class="col-1p5 text-center">
                  <i v-show="bl.loaded_count == bl.unit_count" class="fa fa-check text-success pt-1"></i> 
                  <span v-show="bl.loaded_count != bl.unit_count">{{(bl.unit_count - bl.loaded_count) + ' / ' + bl.unit_count}}</span> 
                </div>
                <div class="col-0p5"> <span>{{bl.type}}</span> </div>
                <div class="col-1p5"> <span>{{ bl.supervisor_approval_id ? statusTypes[bl.supervisor_approval_id] : '-' }}</span> </div>
                <div class="col-0p5"> <i v-show="bl.printed" class="fa fa-check text-success"></i> </div>
                <div class="col-4 col-form-label-sm py-1"> </div>
              </div>
            </div>
          </template>
        </template>
          
      </div>

  </template>

</template>

<script>
  import voyageManifestService from '@/services/ManifestStatusService';
  import moment from 'moment';
  import store from '@/store/user';
  export default {
     watch: {
      itemData: function (val) {
        this.voyage = val    
      },
    },
    data() {
      return {
        generatedHTML: '',
        itemLoaded: false,
        itemLoading: false,
        listLoading: false,
        sendingRequest: false,
        voyage: { ports: [] },
        statusTypes: ['', '', 'Requested', 'Declined', 'Accepted'],
        nmtCompany: 1,
        reversePortOrder: false,
        hasChannelSet: false,
        tabType: 1,
        supervisorComments: "",
        nmtComments: "",
        showPreview: true,
        chosenPorts: [],
        chosenPortsReverse: [],
        chosenPols: [],
        chosenPods: [],
        operatedByOptions: [],
        operatedByOfficeId: null,
        selectedBL: {id: null},
        allBLs: {},
        portsIdCode: {},
        canEdit: false,
        canManifest: false,
        formActive: false
      };
    },
    emits: ['check'],

    methods: {
      active(value){
          this.formActive = value;
      },
      validate(){
        return false;
      },
      getData() {
        return this.voyage
      },
      filterByOperator(){
        const id = this.voyage.id + 1 - 1;
        this.voyage = {};
        this.reset();
        this.listLoading = true;
        voyageManifestService.show(id, this.operatedByOfficeId).then(response => this.setData(response.data));
      },
      reset(){
        const overview = this.$parent.$parent.$parent;
        overview.supervisorComments = '';
        overview.nmtComments = '';
        overview.chosenBLLength = 0;
        overview.tabType = 1;
        overview.blInfo = {};
        this.hasChannelSet = false;
        const els = document.querySelectorAll("#manifest-status-popup .collapse.show");
        this.canEdit = store.getters.getCompanyHq || store.getters.getDocumentationClearance ? true : false;
        this.canManifest = store.getters.getCompanyHq ? true : false;
        els.forEach(el => el.classList.remove('show'));
      },
      setData(data) {
        this.voyage = data;
        this.chosenPorts = [];
        this.chosenPortsReverse = [];
        this.chosenPols = [];
        this.chosenPods = [];
        this.allBLs = {};
        this.operatedByOptions = data.operators;
        this.operatedByOptions.forEach(opt => opt.name = opt.relation.name);
        this.operatedByOptions.sort((a, b) => a.name > b.name ? 1 : -1);
        this.operatedByOfficeId = data.operated_by_office_id;
        this.listLoading = false;
        if(!this.hasChannelSet){
          this.generatedHTML = '';
          this.formatType = 1;
          this.manifestType = 3;
          this.xmlPreview = '';
          this.showPreview = true;
          this.reversePortOrder = false;
          const el = document.getElementById('detailsSpan');
          if(el){
            el.innerHTML = this.$t('navigation.manifest_status') + ': ' + data.vessel.name + ' ' + data.number;
            this.$nextTick(() => el.previousElementSibling.innerHTML = "");
          }
        }
        for(let pol in data.ports){
          const newpol = data.ports[pol];
          this.portsIdCode[newpol.id] = newpol.code;
          let commstatuses = this.voyage.voyage_port_statuses.filter(cs => cs.port_of_loading_id == newpol.id);
          if(commstatuses.length == 0) commstatuses = [this.voyage.voyage_port_statuses.find(cs => cs.port_of_loading_id === null)];
          let commstatus = commstatuses.find(cs => cs.status == 'open');
          if(!commstatus) commstatus = commstatuses.find(cs => cs.status == 'closed');
          if(!commstatus){
            commstatuses.sort((a, b) => {return a.published_date < b.published_date});
            commstatus = commstatuses[0];
          }
          let blcount = 0;
          let approvedcount = 0;
          let unitcount = 0;
          let loadedcount = 0;
          let requestcount = 0;
          for(let pod in newpol.pods){
            const newpod = newpol.pods[pod];
            blcount += newpod.bills_of_lading.length;
            approvedcount += newpod.bills_of_lading.filter(bl => (bl.supervisor_approval_id == 4)).length;
            requestcount += newpod.bills_of_lading.filter(bl => bl.supervisor_approval_id == 2).length;
            for(let bl of newpod.bills_of_lading){
              unitcount += bl.unit_count;
              loadedcount += bl.loaded_count;
            }
          }
          let newPOLIndex = this.chosenPorts.push({
            id: newpol.id,
            name: newpol.name,
            chosen: false,
            code: pol,
            pods: [],
            bl_count: blcount,
            status: commstatus ? commstatus.status : 'open',
            publish_info: commstatus && commstatus.published_date ? (this.setPublishedDateString(commstatus.published_date) + ' - ' + commstatus.user.name) : '',
            approved_count: approvedcount,
            unit_count: unitcount,
            loaded_count: loadedcount,
            request_count: requestcount
          });
          this.voyage.ports[pol].BLsByNumber = [];
          for(let pod in newpol.pods){
            const newpod = newpol.pods[pod];
            this.portsIdCode[newpod.id] = pod;
            let commstatus = this.voyage.voyage_port_statuses.find(cs => (cs.port_of_loading_id == newpol.id && cs.port_of_discharge_id == newpod.id));
            if(!commstatus) commstatus = this.voyage.voyage_port_statuses.find(cs => (cs.port_of_loading_id === null && cs.port_of_discharge_id == newpod.id));
            if(!commstatus){
              const newstatusindex = this.voyage.voyage_port_statuses.push({
                commodity_id: null,
                port_of_discharge_id: newpod.id,
                port_of_loading_id: newpol.id,
                published_by_user_id: null,
                published_date: null,
                status: 'open',
                voyage_id: this.voyage.id
              });
              commstatus = this.voyage.voyage_port_statuses[newstatusindex - 1];
            }
            const blApprovedCount = newpod.bills_of_lading.filter(bl => (bl.supervisor_approval_id == 4)).length;
            const blRequestCount = newpod.bills_of_lading.filter(bl => bl.supervisor_approval_id == 2).length;
            let blUnitCount = 0;
            let blLoadedCount = 0;
            newpod.bills_of_lading.forEach(bl => {
              blUnitCount += bl.unit_count;
              blLoadedCount += bl.loaded_count;
            });
            let newPODIndex = this.chosenPorts[newPOLIndex - 1].pods.push({
              id: newpod.id,
              name: newpod.name,
              chosen: false,
              expanded: false,
              code: newpod.code,
              bl_count: newpod.bills_of_lading.length,
              status: commstatus ? commstatus.status : 'open',
              publish_info: commstatus && commstatus.published_date ? (this.setPublishedDateString(commstatus.published_date) + ' - ' + commstatus.user.name) : '',
              approved_count: blApprovedCount,
              unit_count: blUnitCount,
              loaded_count: blLoadedCount,
              request_count: blRequestCount,
              bills_of_lading: []
            });
            let foundPortIndex = this.chosenPortsReverse.findIndex(port => port.id === newpod.id);
            let foundPortSubIndex = 0;
            if(foundPortIndex >= 0){
              foundPortSubIndex = this.chosenPortsReverse[foundPortIndex].pods.push({ 
                id: newpol.id, 
                name: newpol.name, 
                chosen: false, 
                bills_of_lading: [],
                bl_count: newpod.bills_of_lading.length,
                status: commstatus ? commstatus.status : 'open',
                publish_info: commstatus && commstatus.published_date ? (this.setPublishedDateString(commstatus.published_date) + ' - ' + commstatus.user.name) : '',
                approved_count: blApprovedCount,
                unit_count: blUnitCount,
                loaded_count: blLoadedCount,
              }) - 1;
              this.chosenPortsReverse[foundPortIndex].approved_count += blApprovedCount;
              this.chosenPortsReverse[foundPortIndex].unit_count += blUnitCount;
              this.chosenPortsReverse[foundPortIndex].loaded_count += blLoadedCount;
              this.chosenPortsReverse[foundPortIndex].bl_count += blcount;
            }
            else{
              foundPortIndex = this.chosenPortsReverse.push({
                id: newpod.id,
                name: newpod.name,
                chosen: false,
                bl_count: blcount,
                status: commstatus ? commstatus.status : 'open',
                publish_info: commstatus && commstatus.published_date ? (this.setPublishedDateString(commstatus.published_date) + ' - ' + commstatus.user.name) : '',
                approved_count: blApprovedCount,
                pods: [{ 
                  id: newpol.id, 
                  name: newpol.name, 
                  chosen: false, 
                  bills_of_lading: [],
                  bl_count: newpod.bills_of_lading.length,
                  status: commstatus ? commstatus.status : 'open',
                  publish_info: commstatus && commstatus.published_date ? (this.setPublishedDateString(commstatus.published_date) + ' - ' + commstatus.user.name) : '',
                  approved_count: blApprovedCount,
                  unit_count: blUnitCount,
                  loaded_count: blLoadedCount,
                }]
              }) - 1;
            }
            newpod.bills_of_lading.forEach(bl => {
              this.allBLs[bl.id] = bl;
              this.chosenPorts[newPOLIndex - 1].pods[newPODIndex - 1].bills_of_lading.push({
                chosen: 0,
                id: bl.id,
                name: bl.number,
                is_carrier_bl: !bl.job_booking_voyage_manifest.is_nmt_bl,
                type: bl.bl_type,
                unit_count: bl.unit_count,
                loaded_count: bl.loaded_count,
                customer: bl.customer_name ? bl.customer_name : (bl.customer ? bl.customer : '-'),
                printed: bl.original_downloaded,
                office: bl.office,
                branch: bl.branch,
                supervisor_approval_id: bl.supervisor_approval_id,
                supervisor_approved: bl.supervisor_approval_id == 4,
                supervisor_comment: bl.supervisor_comment,
                nmtcompany_comment: bl.nmtcompany_comment
              });
              this.chosenPortsReverse[foundPortIndex].pods[foundPortSubIndex].bills_of_lading.push({
                chosen: 0,
                id: bl.id,
                name: bl.number,
                is_carrier_bl: !bl.job_booking_voyage_manifest.is_nmt_bl,
                type: bl.bl_type,
                unit_count: bl.unit_count,
                loaded_count: bl.loaded_count,
                customer: bl.customer_name ? bl.customer_name : (bl.customer ? bl.customer : '-'),
                printed: bl.original_downloaded,
                office: bl.office,
                branch: bl.branch,
                supervisor_approval_id: bl.supervisor_approval_id,
                supervisor_approved: bl.supervisor_approval_id == 4,
                supervisor_comment: bl.supervisor_comment,
                nmtcompany_comment: bl.nmtcompany_comment
              });
              if(!bl.job_booking_voyage_manifest){
                bl.job_booking_voyage_manifest = {
                  bill_of_lading_id: bl.id,
                  is_approved_by_supervisor: false,
                  is_consolidated: false,
                  is_nmt_bl: false,
                  job_booking_voyage_id: bl.job_booking_id
                }
              }
              this.voyage.ports[pol].BLsByNumber.push({
                id: bl.id,
                form_id: newpod.id + '-' + bl.id,
                job_booking_voyage_manifest: bl.job_booking_voyage_manifest,
                number: bl.number,
                booking_index: Number(bl.booking_number.slice(-2)) - 1,
                pod_id: newpod.id,
                job_id: bl.job_id,
                job_booking_id: bl.job_booking_id,
                unit_count: bl.unit_count,
                pol: bl.pol,
                pod: bl.pod,
                code: newpod.code,
                loaded_count: bl.loaded_count,
                printed: bl.original_downloaded,
                office: bl.office,
                branch: bl.branch,
                customer: bl.customer_name ? bl.customer_name : (bl.customer ? bl.customer : '-'),
                is_accepted: bl.bill_of_lading_status_id == 3,
                status: commstatus.status,
                supervisor_approval_id: bl.supervisor_approval_id,
                supervisor_comment: bl.supervisor_comment,
                nmtcompany_comment: bl.nmtcompany_comment
              });
            });
          }
          this.voyage.ports[pol].BLsByNumber.sort((a,b) => { return a.number > b.number ? 1 : -1 });
        }

        if(!this.hasChannelSet){
          window.activeChannel = `return-manifest-${this.voyage.id}`;
          window.Echo.channel(window.activeChannel)
            .listen('.listen-manifest', (e) => {
              console.log('broadcast', e);
              let foundPOL = this.chosenPorts.find(port => port.id == e.portOfLoadingId);
              if(foundPOL){
                foundPOL.approved_count += (e.checklistValue ? 1 : -1);
                let foundPOD = foundPOL.pods.find(port => port.id == e.portOfDischargeId);
                if(foundPOD){
                  foundPOD.approved_count += (e.checklistValue ? 1 : -1);
                }
                let foundBL = this.voyage.ports[foundPOL.code].BLsByNumber.find(bl => bl.job_booking_voyage_manifest.id == e.checklistId);
                if(foundBL){
                  foundBL.job_booking_voyage_manifest.is_approved_by_supervisor = e.checklistValue;
                }
              }
          });
          this.hasChannelSet = true;
        }
      },

      setPublishedDateString(date){
        const dateItem = date ? new Date(date) : new Date();
        return new Intl.DateTimeFormat('en-US', { dateStyle: 'long', timeStyle: 'short', hour12: false }).format(dateItem).replace(" at ", " ");
      },

      setAccepted(bl, pol, pod){
        const prevStatusId = (bl.supervisor_approval_id + 1) - 1;
        const newApprovalId = bl.supervisor_approval_id != 4 ? 4 : 1;
        bl.supervisor_approval_id = newApprovalId;
        voyageManifestService.sendStatusRequest(bl.id, {'supervisor_approval_id': newApprovalId, 'supervisor_comment': '', 'nmtcompany_comment': ''}).then(response => {
          if(this.selectedBL && this.selectedBL.id == bl.id){
            this.autoUpdateOverview(newApprovalId, prevStatusId, this.selectedBL.pod, this.selectedBL.pol);
            this.$emit('check', newApprovalId);
          }
          else{
            this.autoUpdateOverview(newApprovalId, prevStatusId, pod, pol);
          }
        }).catch(error => {
          console.log('error', error);
        });
      },

      setStatusRequest(){
        const prevStatusId = parseInt(this.selectedBL.supervisor_approval_id);
        this.sendingRequest = true;
        return voyageManifestService.sendStatusRequest(this.selectedBL.id, {'supervisor_approval_id': this.tabType, 'supervisor_comment': this.supervisorComments, 'nmtcompany_comment': this.nmtComments}).then(response => {
          this.selectedBL.supervisor_approval_id = this.tabType;
          if(this.supervisorComments)
            this.selectedBL.supervisor_comment = this.supervisorComments;
          if(this.nmtComments)
            this.selectedBL.nmtcompany_comment = this.nmtComments;
          this.sendingRequest = false;
          this.$toast.success(response.data.message);
          this.autoUpdateOverview(this.tabType, prevStatusId, this.selectedBL.pod, this.selectedBL.pol);
        }).catch(error => {
          console.log('error', error);
        });
      },

      autoUpdateOverview(tabType, prevStatusId, pod, pol){
        if(tabType == 2 && prevStatusId != 2){
            pod.request_count++;
            pol.request_count++;
          }
          if(tabType != 2 && prevStatusId == 2){
            pod.request_count--;
            pol.request_count--;
          }
          if(tabType != 4 && prevStatusId == 4){
            pod.approved_count--;
            pol.approved_count--;
          }
          if(tabType == 4 && prevStatusId != 4){
            pod.approved_count++;
            pol.approved_count++;
          }
      },

      setChosenBLs(e, bl, pol, pod){
        if((e.target.tagName == "INPUT" && this.selectedBL.id == bl.id) || this.sendingRequest) return;
        const overview = this.$parent.$parent.$parent;
        if(this.selectedBL.id == bl.id){
          this.selectedBL = {id: null};
          overview.chosenBLs = '-';
          overview.chosenBLLength = 0;
        }
        else{
          overview.chosenBLLength = 1;
          if(!this.selectedBL.id){
            this.$nextTick(() => e.target.scrollIntoView({ block: "nearest" }));
          }
          this.selectedBL = bl;
          this.selectedBL.pod = pod;
          this.selectedBL.pol = pol;
          overview.chosenBLs = this.allBLs[bl.id].number;
          overview.supervisorComments = bl.supervisor_comment;
          overview.nmtComments = bl.nmtcompany_comment;
          overview.tabType = bl.supervisor_approval_id;
          overview.blInfo = bl;
        }
      },

      setPortOrder(){
        this.reversePortOrder = !this.reversePortOrder;
      },

      setManifestTypes(type, format){
        this.manifestType = type;
        this.formatType = format;
        this.generatedHTML = '';
      },

      closeInnerModel(e){
        if(e.target.classList.contains('d-block')){
          this.itemLoaded = false;
        }
      },

      publish(e, port1, port2 = null){
        console.log(e.target.value, port1, port2);
        const value = e.target.value;
        const isPublished = value == 'published';
        const name = port2 ? (port1.name + ' - ' + port2.name) : port1.name;
        if(isPublished && !confirm(this.$t('manifest.confirm_publish', [name]))){
          e.preventDefault();
          return;
        }
        let publishDate = null;
        let publishUser = null;
        if(isPublished){
          publishDate = moment().format('YYYY-MM-DD');
          publishUser = store.getters.userId;
        }
        let publishInfo = isPublished ? (this.setPublishedDateString(null) + ' - ' + store.getters.getName) : null;
        if(port2){
          let commstatus = this.voyage.voyage_port_statuses.find(cs => ((cs.port_of_loading_id == port1.id || cs.port_of_loading_id === null) && cs.port_of_discharge_id == port2.id));
          if(commstatus){
            commstatus.status = value;
            commstatus.published_by_user_id = publishUser;
            commstatus.published_date = publishDate;
            port2.status = value;
            port2.publish_info = publishInfo;
            let allPodsArePublished = true;
            if(isPublished){
              port1.pods.forEach(port => {
                if(port.status != 'published'){
                  allPodsArePublished = false;
                }
              });
              if(allPodsArePublished){
                port1.publish_info = publishInfo;
                port1.status = 'published';
              }
            }
          }
        }
        else{
          let commstatuses = this.voyage.voyage_port_statuses.filter(cs => (cs.status != 'published' && (cs.port_of_loading_id == port1.id || cs.port_of_loading_id === null)) );
          commstatuses.forEach(commstatus => {
            commstatus.status = value;
            commstatus.published_by_user_id = publishUser;
            commstatus.published_date = publishDate;
            port1.publish_info = publishInfo;
            port1.status = value;
            port1.pods.forEach(port => {
              if(port.status != 'published'){
                port.publish_info = publishInfo;
                port.status = value;
              }
            });
          });
        }
        this.voyage.ports[port1.code].BLsByNumber.forEach(bl => {
          if(port2 === null || port2.code === bl.code){
            bl.status = value;
          }
        });
        voyageManifestService.updateOpenClosed(this.voyage.id, {'lines': this.voyage.voyage_port_statuses}).then(response => {
          console.log('OK closing', response);
        }).catch(error => {
          console.log('error', error);
        });
      },

      unpublish(port1, port2 = null){
        if(confirm("Are you sure you want to unpublish " + port1.name + (port2 ? (" - " + port2.name) : "") + "?")){
          this.publish({target: {value: "open"}}, port1, port2);
        }
      },

      hardCopy(obj){
        return JSON.parse(JSON.stringify(obj));
      }
    }
  };
</script>

<style scoped>
.published{
  padding-top: 2px !important;
  padding-bottom: 3px !important;
}
.col-0p5{
  flex: 0 0 auto;
  width: 5%;
  padding-right: 0
}
.col-1p5{
  flex: 0 0 auto;
  width: 11.6%
}
.bg-approved{
  background-color: #d5e9c0;
}
.bg-declined{
  background-color: #e6b9c0;
}
.bg-chosen{
  background-color: #c0d0e9 !important;
}
.fa-chevron-right{
  rotate: 0deg;
  transition: rotate 0.15s linear
}
.rotate-90deg{
  rotate: 90deg
}
.header-part-1 .data-tr{
  padding-top: 2px;
  padding-bottom: 2px;
}
.data-tr > div > *:not(.fa-check) {
  vertical-align: text-top
}
.fa-check {
  vertical-align: middle
}
.header-part-1 > .data-tr:not(:first-child), .header-part-1 > div .data-tr{
  border-top: 1px solid #ddd;
}
</style>