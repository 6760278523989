<template>
    <div>
        <Overview 
            :moduleName="this.formatModuleName(this.$route.name, this.$i18n, false)"
            :moduleNamePlural="this.formatModuleName(this.$route.name, this.$i18n)"
            :sort="'created_at'"
            :itemNameField="'number'"
            :itemService=itemService
            :itemFormRef=itemFormRef
            :sortOrder="2"
            modalWidth="1400px"
            :selectFields=selectFields
            :showAddButton="false"
            :blueHeader="true"
        >   
        <template #columns>
            <Column field="vessel.name" :header="$tc('navigation.vessels', 1)"></Column>
            <Column field="number" :header="$t('navigation.voyages')" style="width: 120px"></Column>
            <Column field="carrier.relation.name" :header="$tc('navigation.carriers', 1)"></Column>
            <Column field="voyage_status_id" header="Booking status" style="width: 160px">
                <template #body="slotProps">
                    <span v-if="slotProps.data.job_booking_voyage_manifests_count > 0 && !slotProps.data.has_unpublished_bls" class="badge" data-status="archived">
                        Published
                    </span>
                    <span v-else class="badge" :data-status="$t('voyage_status.'+slotProps.data.voyage_status_id).toLowerCase()">
                        {{slotProps.data.voyage_status_id ? $t('voyage_status.'+slotProps.data.voyage_status_id) : ''}}
                    </span>
                </template>
            </Column>
            <Column field="created_at" header="Created" style="width: 100px">
                <template #body="slotProps"> {{formatDate(slotProps.data.created_at, 'shortDate')}} </template>
            </Column>
            <Column field="job_booking_voyage_manifests_count" header="B/Ls" style="width: 80px" />
        </template>

        <template #form>
            <ManifestStatusForm ref="ItemForm" :itemData="item" @check="setTabTypeReverse" />
        </template>

        <template #modal-footer>
            <section id="print-footer" v-show="chosenBLLength">
                <h5 class="dm-sans fw-bold">{{$t('masterdata.status_comments')}}: {{chosenBLs ? chosenBLs : '-'}}</h5>
                <div class="w-75 mb-2">
                    <FormItem id="tab_id" v-model="tabType" type="radiogroup" sizeItem="12" :options="tabTypes" @onchange="setTabTypes" :disabled="!canEdit" />
                </div>
                <div v-if="blInfo.id" style="font-size: 0.79rem">
                    <b class="d-inline-block" style="width: 100px">{{$t('navigation.offices')}}:</b>{{ blInfo.office }} <br />
                    <b class="d-inline-block py-1" style="width: 100px">{{$t('form.branch')}}:</b>{{ blInfo.branch ? blInfo.branch : '-' }} <br />
                    <b class="d-inline-block" style="width: 100px">{{$t('navigation.customers')}}:</b>{{ blInfo.customer }} <br />
                </div>
                <div class="row">
                    <div class="col-5">
                        <FormItem id="supcomm" v-model="supervisorComments" type="textarea" sizeItem="12" :labelAbove="'Supervisor:'" @onchange="setComments(1)" :disabled="!canEdit" />
                    </div>
                    <div class="col-1">&emsp;</div>
                    <div class="col-5">
                        <FormItem id="nmtcomm" v-model="nmtComments" type="textarea" sizeItem="12" :labelAbove="'NMT company:'" @onchange="setComments(2)" />
                    </div>
                    <div class="col-1 d-flex align-items-end">
                        <FormItem type="download" :label="$t('form.save')" :service="setStatusRequest" :options="{send: true}" startTag="fa fa-save" />
                    </div>
                </div>
            </section>
        </template>

        </Overview>
    </div>
</template>

<script>
import Overview from '@/components/Overview.vue';

import voyageManifestService from '@/services/ManifestStatusService';
import Column from '@/components/column';
import ManifestStatusForm from '@/components/manifeststatus/ManifestStatusForm.vue';
import store from '@/store/user';

export default {
    components: {
        Overview,
        Column,
        ManifestStatusForm
    },
    data() {
        return {
            item: {},
            itemService: voyageManifestService,
            itemFormRef: null,
            blInfo: {},
            selectFields: [
                "id",
                "number",
                "updated_at",
                "created_at",
                "region_type_id",
                "vessel_id",
                "carrier_id",
                "voyage_status_id",
                {
                    "vessel": [
                        "id",
                        "code",
                        "name"
                    ]
                },
                {
                    "carrier": [
                        "id",
                        "relation_id",
                        {
                            "relation": [
                                "id",
                                "name"
                            ]
                        }
                    ]
                }
            ],
            tabType: 1,
            supervisorComments: "",
            nmtComments: "",
            tabTypes: [{id: 1, name: 'None'}, {id: 2, name: 'Request supervisor approval'}, {id: 3, name: 'Declined'}, {id: 4, name: 'Accepted'}],
            chosenBLs: "",
            canEdit: false,
            itemLoading: false,
            chosenBLLength: 0
        }
    },
    methods: {
        setTabTypes(){
            this.$refs.ItemForm.tabType = this.tabType;
        },
        setTabTypeReverse(n){
            this.tabType = n;
        },
        setComments(field){
            this.$refs.ItemForm[field == 1 ? 'supervisorComments' : 'nmtComments'] = (field == 1 ? this.supervisorComments : this.nmtComments);
        },
        setStatusRequest(){
            this.setTabTypes();
            this.setComments(0);
            this.setComments(1);
            return this.$refs.ItemForm.setStatusRequest();
        }
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;
        this.canEdit = store.getters.getCompanyHq || store.getters.getDocumentationClearance ? true : false;
    }
}
</script>
<style>
#manifest-status-popup .modal-footer{
    background-color:#f1f0ec !important;
}
#manifest-status-popup #savebutton{
    display: none;
}
#print-footer{
    width: 100%;
}
#generatebutton{
    margin-left: 8.25rem;
    width: 15.25rem;
}
</style>